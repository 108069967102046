import * as React from "react";
import { Alert, Button, Col, Form, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import { OrganizerEvent } from "src/Organizer/models/OrganizerComponentModels";
import { OverlayLoader } from "../../../../components/helper/OverlayLoader";
import { IAccessViewModel, IOneHubAccessCodeDetail } from "src/components/common/TaxReturn";
import { OnehubAccessCodeStatus, statusButtonText } from "src/components/helper/StatusButtonHelper";
import { Link } from "react-router-dom";
import { ClientTypes } from './../../../../components/common/TaxReturn';

let moment = require("moment");
interface IViewAccessCodeProps {
    model: IAccessViewModel;
    oneHubModel: IOneHubAccessCodeDetail[];
    onCancel(): void;
    show: boolean;
    onGenerateOTP: (documentId: number, clientGUID: string, clientType: ClientTypes, callBack: () => void) => void;
    loading: boolean;
    onGenerateOneHubOTP: (email: string, callback: () => void) => void;
    isUTELinked: boolean;
}

interface IOneHubColumn {
    clientName: string;
    clientEmail: string;
    otpDate: Date;
    accessCode: string;
    accessCodeStatus: string;
}
interface ColumnNames {
    header: string;
    key: string;
    isKey: boolean;
    dataFormat: any;
    width: string;
    toolTip: boolean;
}
interface IColumnValues {
    signersName: string;
    createdOn: Date;
    OTP: string;
    status: string ;
    email: string;
    isDeceased: boolean;
    documentId: number;
    clientType: ClientTypes;
    clientGUID: string;
    date: Date;
}

const msg = {
    saving: "Generating OTP...",
    loading: "Loading, please wait..."
};

const successMsg = {
    generatedAccessCode: "Access code generated successfully"
};

enum AccessCodeTabs {
    Direct,
    OneHub
}

export enum AlertType {
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "danger",
    INFO = "info"
}

export const ViewAccessCode: React.FC<IViewAccessCodeProps> = (props) => {
    const [alertMessage, setAlertMessage] = React.useState("");
    const [oneHubAlertMessage, setOneHubAlertMessage] = React.useState("");
    const [alertType, setAlertType] = React.useState<AlertType>(AlertType.SUCCESS);
    const [oneHubAlertType, setOneHubAlertType] = React.useState<AlertType>(AlertType.SUCCESS);
    const [activeKey, setActiveKey] = React.useState(AccessCodeTabs.Direct);

    const defaultType = (cell: any, row: IColumnValues) => {
        return cell;
    };
    const handleTabSelect = (index: any) => setActiveKey(parseInt(index.toString()));

    const generateOTPButton = (cell: any, row: IColumnValues) => {
        // style added for fixing the bug-92275
        let style: string =
            row.email === null || row.email?.trim().length === 0 || row.isDeceased
                ? "generate_link_disabled"
                : "generate_link_enabled";
        return (
            <Link
                to="#"
                className={style}
                onClick={(event) => {
                    onGenerateOTP(row);
                }}
                title="Generate Access Code"
            >
                Generate Access Code
            </Link>
        );
    };
    const generateOneHubOTPButton = (cell: any, row: IOneHubColumn) => {
        // style added for fixing the bug-92275
        let style: string =
            row.clientEmail === null || row.clientEmail?.trim().length === 0 ? "generate_link_disabled" : "generate_link_enabled";
        return (
            <Link
                to="#"
                className={style}
                onClick={(event) => {
                    onGenerateOneHubOTP(row);
                }}
                title="Generate Access Code"
            >
                Generate Access Code
            </Link>
        );
    };
    const _toolTip = (cell: any, row: any) => {
        let accessAction;
        props.model.accessCodeDetails.map((value, index) => {
            accessAction = getDifferenceInDate(value.createdOn) > 20 ? "Generate Access Code" : "";
        });
        return `${accessAction}`;
    };
    const onGenerateOTP = (row: IColumnValues) => {
        props.onGenerateOTP(row.documentId, row.clientGUID, row.clientType, triggerAlert);
    };

    const onGenerateOneHubOTP = (row: IOneHubColumn) => {
        props.onGenerateOneHubOTP(row.clientEmail, triggerOneHubAlert);
    };

    const getDifferenceInDate = (createdDate: Date) => {
        let date1: any = new Date();
        let date2: any = moment.utc(createdDate).toDate();
        let minute = (date1.getTime() - date2.getTime()) / 60000;
        return minute;
    };
    const onCancel = () => {
        setAlertMessage("");
        setAlertType(AlertType.SUCCESS);
        setOneHubAlertMessage("");
        setOneHubAlertType(AlertType.SUCCESS);
        props.onCancel();
    };

    const triggerAlert = () => {
        setAlertMessage(successMsg.generatedAccessCode);
        setAlertType(AlertType.SUCCESS);
    };

    const triggerOneHubAlert = () => {
        setOneHubAlertMessage(successMsg.generatedAccessCode);
        setOneHubAlertType(AlertType.SUCCESS);
    };

    const statusDataFormatType = (cell: any, row: IColumnValues) => {
        if (row.OTP != "NA") {
            return getDifferenceInDate(row.createdOn) > 20 ? "Expired" : "Active";
        } else if (
            row.OTP == "NA" &&
            props.model.clientEvents.find((x) => x.eventId == OrganizerEvent.OTPAuthenticated) != undefined &&
            props.model.clientEvents.some(
                (x) => x.eventId == OrganizerEvent.OTPAuthenticated && x.actedBy.firstName == row.signersName
            )
        ) {
            return "Authenticated";
        } else if (
            (row.OTP == "NA" &&
                (props.model.clientEvents.length == 0 ||
                    props.model.clientEvents.find((x) => x.eventId == OrganizerEvent.AccessCodeEmail) == undefined)) ||
            props.model.clientEvents.find(
                (x) => x.eventId == OrganizerEvent.AccessCodeEmail && x.actedBy.firstName == row.signersName
            ) == undefined
        ) {
            return "Not Requested";
        }
    };

    const statusDataFormatTypeOnehub = (cell: any, row: IOneHubAccessCodeDetail) => {
        return statusButtonText(row.accessCodeStatus);
    };

    const getAccessCodeDataType = (cell: any, row: IColumnValues) => {
        if (row.OTP != "NA") {
            return getDifferenceInDate(row.createdOn) > 20 ? "NA" : row.OTP;
        } else {
            return "NA";
        }
    };

    const getOneHubAccessCodeDataType = (cell: any, row: IOneHubColumn) => {
        if (row.accessCode) {
            return getDifferenceInDate(row.otpDate) > 20 ? "NA" : row.accessCode;
        } else {
            return "NA";
        }
    };

    const createdOnDataFormat = (cell: any, row: IColumnValues) => {
        return !row.createdOn || getDifferenceInDate(row.createdOn) > 20
            ? "NA"
            : moment.utc(row.createdOn).local().format("MM/DD/YYYY hh:mm:ss A");
    };

    const createdOnDataFormatOneHub = (cell: any, row: IOneHubColumn) => {
        return !row.otpDate || getDifferenceInDate(row.otpDate) > 20
            ? "NA"
            : moment.utc(row.otpDate).local().format("MM/DD/YYYY hh:mm:ss A");
    }

    const signerNameDataFormat = (cell: any, row: IColumnValues) => {
        return (
            <div title={cell} className="ellipsis" style={{ maxWidth: "95%" }}>
                {cell}
            </div>
        );
    };
    let viewAccessCodeData: any[] = [];
    let oneHubAccessCodeData: any[] = [];
    const columnsDirect = [
        {
            header: "Signer's Name",
            key: "signersName",
            isKey: false,
            dataFormat: signerNameDataFormat,
            tooltip: true,
            columnClassName: "",
        },
        {
            header: "Date",
            key: "createdOn",
            isKey: true,
            dataFormat: createdOnDataFormat,
            tooltip: true,
            columnClassName: "",
        },
        {
            header: "Access Code",
            key: "OTP",
            isKey: false,
            dataFormat: getAccessCodeDataType,
            tooltip: true,
            columnClassName: "",
        },
        {
            header: "Status",
            key: "status",
            isKey: false,
            dataFormat: statusDataFormatType,
            tooltip: true,
            columnClassName: "",
        },
        {
            header: "Action",
            key: "action",
            isKey: false,
            dataFormat: generateOTPButton,
            tooltip: true,
            columnClassName: "",
        }
    ];
    const columnsOneHub = [
        {
            header: "Client Name",
            key: "clientName",
            isKey: false,
            dataFormat: signerNameDataFormat,
            tooltip: true,
            columnClassName: "",
        },
        {
            header: "Client Email ID",
            key: "clientEmail",
            isKey: false,
            dataFormat: signerNameDataFormat,
            tooltip: true,
            columnClassName: "",
        },
        {
            header: "Date",
            key: "createdOn",
            isKey: true,
            dataFormat: createdOnDataFormatOneHub,
            tooltip: true,
            columnClassName: "",
        },
        {
            header: "Access Code",
            key: "accessCode",
            isKey: false,
            dataFormat: getOneHubAccessCodeDataType,
            tooltip: true,
            columnClassName: "",
        },
        {
            header: "Status",
            key: "status",
            isKey: false,
            dataFormat: statusDataFormatTypeOnehub,
            tooltip: true,
            columnClassName: "",
        },
        {
            header: "Action",
            key: "action",
            isKey: false,
            dataFormat: generateOneHubOTPButton,
            tooltip: true,
            columnClassName: "",
        }
    ];
    let minDate = new Date();
    minDate.setFullYear(1, 0, 1);
    minDate.setHours(0, 0, 0, 0);
    if (props.model.accessCodeDetails) {
        viewAccessCodeData = props.model.accessCodeDetails.map((value, index) => {
            return {
                signersName: value.name,
                createdOn:
                    Date.parse(new Date(value.createdOn).toString()) == Date.parse(minDate.toString()) ? null : value.createdOn,
                OTP: !value.otp ? "NA" : value.otp,
                status: getDifferenceInDate(value.createdOn) > 20 ? "EXPIRED" : "ACTIVE", //"EXPIRED",
                documentId: value.documentId,
                email: value.email,
                isDeceased: value.isDeceased,
                clientType: value.clientType,
                clientGUID: value.clientGuid,
                date: value.createdOn
            };
        });
    }
    if (props.oneHubModel) {
        oneHubAccessCodeData = props.oneHubModel as any;
    }
    let modalBodyDirect = props.model.accessCodeDetails ? (
        <BootstrapTable data={viewAccessCodeData}>
            {columnsDirect.map((value, index) => {
                return (
                    <TableHeaderColumn
                        key={index}
                        isKey={value.isKey}
                        dataField={value.key}
                        dataFormat={value.dataFormat}
                        columnClassName={value.columnClassName}
                        columnTitle={value.tooltip}
                    >
                        {value.header}
                    </TableHeaderColumn>
                );
            })}
        </BootstrapTable>
    ) : (
        <OverlayLoader show={props.show} text={"Loading..."} width="94%" />
    );

    let modalBodyOneHub = props.oneHubModel ? (
        <BootstrapTable data={oneHubAccessCodeData}>
            {columnsOneHub.map((value, index) => {
                return (
                    <TableHeaderColumn
                        key={index}
                        isKey={value.isKey}
                        dataField={value.key}
                        dataFormat={value.dataFormat}
                        columnClassName={value.columnClassName}
                        columnTitle={value.tooltip}
                    >
                        {value.header}
                    </TableHeaderColumn>
                );
            })}
        </BootstrapTable>
    ) : (
        <OverlayLoader show={props.show} text={"Loading..."} width="94%" />
    );

    const AccessCodeTab = () => {
        return (
            <Tabs id="view-access-code-tabs" className="gtr-tabs" activeKey={activeKey} onSelect={handleTabSelect} justify>
                <Tab eventKey={AccessCodeTabs.Direct} title="Direct Document">
                    <>
                        <div className="mt-3">
                            <Alert className="px-4" variant={alertType} hidden={!alertMessage}>
                                <i className="fas fa-exclamation-triangle" style={{ marginRight: "5px" }}></i>
                                {alertMessage}
                            </Alert>
                        </div>
                        {modalBodyDirect}
                    </>
                </Tab>
                <Tab eventKey={AccessCodeTabs.OneHub} title="OneHub">
                    <>
                        <div className="mt-3">
                            <Alert className="px-4" variant={oneHubAlertType} hidden={!oneHubAlertMessage}>
                                <i className="fas fa-exclamation-triangle" style={{ marginRight: "5px" }}></i>
                                {oneHubAlertMessage}
                            </Alert>
                        </div>
                        {modalBodyOneHub}
                    </>
                </Tab>
            </Tabs>
        );
    };

    return (
        <Modal className="view-access-code-modal" show={props.show} onHide={onCancel}>
            <Modal.Header closeButton onClick={onCancel} data-test-auto="C98D7824-8C63-4A74-95E6-4D3C73B08677">
                <Modal.Title>
                    <span className="text-secondary fas fa-clock" style={{ color: "grey", marginRight: "5px" }}></span>Access Code
                </Modal.Title>
            </Modal.Header>
            <Form>
                <Modal.Body>
                    <div>
                        <LoadingOverlay style={{ height: "100%" }}>
                            {!props.isUTELinked && <div style={{ marginLeft: "5px", marginRight: "5px" }}>
                                <Alert variant={alertType} hidden={!alertMessage}>
                                    <i className="fas fa-exclamation-triangle" style={{ marginRight: "5px" }}></i>
                                    {alertMessage}
                                </Alert>
                            </div>}
                            {props.isUTELinked ? <AccessCodeTab /> : modalBodyDirect}
                            <Loader loading={props.loading} text={"Loading..."} />
                        </LoadingOverlay>
                    </div>
                </Modal.Body>
            </Form>
            <Modal.Footer style={{ display: "initial" }}>
                <Row>
                    <Col className="access-code-note" sm={8} style={{ fontSize: "12px" }}>
                        Note: Generating a new code will not send a new email
                    </Col>
                    <Col sm={4}>
                        <Button
                            variant="default"
                            data-test-auto="DAE73B5C-9114-46EB-BDE7-0EC8BA0D4B0D"
                            onClick={onCancel}
                            style={{ float: "right" }}
                        >
                            <i className="glyphicon glyphicon-remove"></i>Cancel
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    );
};

export default ViewAccessCode;
